import { publicRuntimeConfig } from 'configuration/app/next-runtime.config';
import { yup } from 'modules/common/validation';
import { setConfig } from 'next/config';

setConfig({ publicRuntimeConfig });

const platformUrl = yup
  .string()
  .trim()
  .default('http://localhost:3002/v01')
  .validateSync(publicRuntimeConfig.NEXT_PUBLIC_PLATFORM_URL);

const backendUrl = yup
  .string()
  .trim()
  .default('http://localhost:3001')
  .validateSync(publicRuntimeConfig.NEXT_PUBLIC_BACKEND_URL);

export const publicConfig = Object.freeze({
  backend: {
    url: backendUrl,
    graphqlUri: `${backendUrl}/graphql`,
    sentry: {
      dsn: yup.string().validateSync(process.env.SENTRY_DSN),
      tracingSampleRate: yup.number().validateSync(process.env.SENTRY_TRACING_SAMPLE_RATE),
    },
    currentBusinessPartnerIdHeader: yup
      .string()
      .trim()
      .default('current-business-partner-id')
      .validateSync(process.env.NEXT_PUBLIC_CURRENT_BUSINESS_PARTNER_ID_HEADER),
  },
  platform: {
    url: platformUrl,
    graphqlUri: `${platformUrl}/graphql`,
    authorizationHeader: yup
      .string()
      .trim()
      .default('roq-platform-authorization')
      .validateSync(process.env.NEXT_PUBLIC_PLATFORM_AUTHORIZATION_HEADER),
    requestIdHeader: yup
      .string()
      .trim()
      .default('request-id')
      .validateSync(process.env.NEXT_PUBLIC_PLATFORM_REQUEST_ID_HEADER),
    socketUrl: yup
      .string()
      .trim()
      .default('http://localhost:3005/socket.io')
      .validateSync(publicRuntimeConfig.NEXT_PUBLIC_SOCKET_BACKEND_URL),
    socketSecure: yup.boolean().default(false).validateSync(process.env.NEXT_PUBLIC_SOCKET_SECURE),
    gatewayEnabled: yup.boolean().default(true).validateSync(process.env.NEXT_PUBLIC_GATEWAY_ENABLED),
  },
  frontend: {
    maxChatCharacters: yup.number().default(1000).validateSync(process.env.NEXT_PUBLIC_MAX_CHAT_CHARACTERS),
    sentry: {
      dsn: yup.string().default('https://0c8f4d6fc855556a21ea0e2fd37c084f@o4508141808975872.ingest.de.sentry.io/4508141810548816').validateSync(process.env.SENTRY_DSN),
      tracingSampleRate: yup.number().default(1.0).validateSync(process.env.SENTRY_TRACING_SAMPLE_RATE),
    },
    notificationFirstLoadCount: yup
      .number()
      .default(20)
      .validateSync(process.env.NEXT_PUBLIC_NOTIFICATION_FIRST_LOAD_COUNT),
    notificationPollingDelaySeconds: yup
      .number()
      .default(15)
      .validateSync(process.env.NEXT_PUBLIC_NOTIFICATION_POLLING_DELAY_SECONDS),
    notificationMaxAgeDays: yup.number().default(28).validateSync(process.env.NEXT_PUBLIC_NOTIFICATION_MAX_AGE_DAYS),
    notificationPageSize: yup.number().default(20).validateSync(process.env.NEXT_PUBLIC_NOTIFICATION_PAGE_SIZE),
  },
  timezone: {
    options: yup
      .array()
      .nullable()
      .validateSync(JSON.parse(process.env.NEXT_PUBLIC_TIMEZONE_LIST ?? null)),
    default: yup.string().trim().default('Europe/Berlin').validateSync(process.env.NEXT_PUBLIC_DEFAULT_TIMEZONE),
  },
  locale: yup.string().trim().default('en-US').validateSync(publicRuntimeConfig.NEXT_PUBLIC_LOCALE_DEFAULT),
  activityTimeInterval: yup.number().default(1800000).validateSync(process.env.NEXT_PUBLIC_ACTIVITY_TIME_INTERVAL), // 30minutes
  cookie: {
    maxAge: yup.number().nullable().default(86400).validateSync(process.env.COOKIE_MAX_AGE),
  },
  hotjar: {
    id: yup.number().nullable().default(5219704).validateSync(process.env.NEXT_PUBLIC_HOTJAR_ID),
    sv: yup.number().nullable().default(6).validateSync(process.env.NEXT_PUBLIC_HOTJAR_SV),
  }
});
